<template>
  <article :class="$style.grid">
    <div :class="[$style.gridItem, $style.gridItemLeft, $style.gridItemComputers]">
      <div :class="$style.gridItemComputersTable">
        <div :class="$style.gridItemComputersTableContent">
          <div :class="$style.buttonsContainer">
            <div :class="[$style.dropdown, $style.buttonContainer]">
              <button :class="$style.addUserButton">
                Добавить пользователя
              </button>
              <div :class="$style.dropdownСontent">
                <a
                  v-for="option in addUserOptions"
                  :key="option.name"
                  :v-bind:value="option.name"
                  :href="option.link"
                  @click="openModal(option.id)"
                >{{ option.name }}</a>
              </div>
            </div>

            <button
              :class="[$style.addUserButton, $style.buttonContainer]"
              @click="openModal(addGroupElementId)"
            >
              Добавить группу
            </button>
          </div>
          <div :class="$style.gridItemComputersTableContentTable">
            <dragTreeTable
              :data="treeData"
              :on-drag="onTreeDataChange"
              :before-drag-over="allowDrag"
              @deleteItem="openDeleteModal"
              @saveNewName="changeName"
              @redirect="redirect"
            />
          </div>
          <div :class="$style.compNumber">
            <div :class="$style.compNumberText">
              Количество компьютеров: {{ compNumber }} / {{ maxComputers }}
            </div>
            <div :class="$style.compNumberText">
              Количество пользователей: {{ userNumber }} / {{ maxUsers }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[$style.gridItem]">
      <div
        v-if="selectedComputer"
        :class="$style.settings"
      >
        <div :class="$style.computerHeader">
          <div>
            <div :class="$style.title">
              {{ selectedComputer.title }}
            </div>
            <div :class="$style.selectContainer">
              <label :class="$style.profileLabel"> Профиль настроек</label>
              <div :class="$style.vueSelect">
                <div class="agentsSelect">
                  <vue-select
                    v-model="selectedProfile"
                    :class="$style.select"
                    :options="profiles"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    @option:selected="openModal('changeProfile')"
                  >
                    <template v-slot:list-footer>
                      <button
                        title="Добавить"
                        :class="$style.addProfileButton"
                        @click="openModal('addProfile')"
                      >
                        +
                      </button>
                    </template>
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      Нет профилей для выбора
                    </template>
                  </vue-select>
                </div>
              </div>
              <button
                :class="[$style.button, $style.buttonEdit]"
                @click="openModal('editProfList')"
              >
                Редактировать список профилей
              </button>
            </div>
          </div>
          <button
            v-show="openedSettingsBlock"
            :class="[
              $style.button,
              $style.buttonSave,
              { [$style.buttonActive]: smthChanged },
            ]"
            @click="saveAllSettings(selectedProfile)"
          >
            Сохранить настройки
          </button>
          <button
            v-show="openedSettingsBlock"
            :class="[
              $style.button,
              $style.buttonSave,
              { [$style.buttonRedActive]: smthChanged },
            ]"
            @click="cancelChanges()"
          >
            Отменить изменения
          </button>
          <tabset
            class="agentTabs"
            :tabs="tabs"
            :opened="true"
            :hide-arrow="true"
            :class="$style.tabs"
            :tab-style="tabStyle"
            @tabClick="openNewTab"
          />
          <div v-show="tabs[0].active">
            <div :class="$style.chips">
              <chip
                v-for="category in categories"
                :key="category.name"
                :slug="category.slug"
                :class="$style.chip"
                :route="{
                  active: 'agents-agent-settings',
                  inactive: 'agents',
                }"
              >
                <div :class="$style.chipTitle">
                  <img
                    v-if="category.slug === 'log'"
                    src="@/assets/images/icons/navigation/size.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'common'"
                    src="@/assets/images/icons/navigation/settings.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'screenshots'"
                    src="@/assets/images/icons/navigation/screenshot.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'drives'"
                    src="@/assets/images/icons/computer/categories/devices.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'files'"
                    src="@/assets/images/icons/navigation/data.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'shadowCopy'"
                    src="@/assets/images/icons/navigation/copy.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'internet'"
                    src="@/assets/images/icons/navigation/internet.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'worktime'"
                    src="@/assets/images/icons/navigation/time.svg"
                    :class="$style.deleteIcon"
                  >
                  <img
                    v-if="category.slug === 'webcam'"
                    src="@/assets/images/icons/navigation/webcam.svg"
                    :class="$style.deleteIcon"
                  >
                  {{ category.name }}
                </div>
              </chip>
            </div>
          </div>
          <div v-show="tabs[1].active">
            <div :class="$style.chips">
              <chip
                v-for="category in securityCategories"
                :key="category.name"
                :slug="category.slug"
                :class="$style.chip"
                :route="{
                  active: 'agents-agent-security',
                  inactive: 'agents',
                }"
              >
                <div :class="$style.chipTitle">
                  {{ category.name }}
                </div>
              </chip>
            </div>
          </div>
          <div v-show="tabs[2].active">
            <div :class="$style.chips">
              <chip
                v-for="category in notificationCategories"
                :key="category.name"
                :slug="category.slug"
                :class="$style.chip"
                :route="{
                  active: 'agents-agent-notifications',
                  inactive: 'agents',
                }"
              >
                <div :class="$style.chipTitle">
                  {{ category.name }}
                </div>
              </chip>
            </div>
          </div>
        </div>
        <div :class="$style.settings">
          <router-view />
        </div>
      </div>
    </div>
    <user-modal
      :id="addUserOptions[0].id"
      :class="$style.modal"
      :users="computers"
      @close="closeModal(addUserOptions[0].id)"
      @update="fetch()"
    />
    <user-list-modal
      :id="addUserOptions[1].id"
      :class="$style.modal"
      :users="computers"
      @close="closeModal(addUserOptions[1].id)"
      @update="fetch()"
    />
    <group-modal
      :id="addGroupElementId"
      :class="$style.modal"
      @close="closeModal(addGroupElementId)"
      @update="fetch()"
    />
    <delete-user-modal
      id="deleteUser"
      :class="$style.modal"
      :selected="selectedItem"
      type="user"
      @close="closeModal('deleteUser')"
      @update="fetch()"
    />
    <delete-group-modal
      id="deleteGroup"
      :class="$style.modal"
      :selected="selectedItem"
      @close="closeModal('deleteGroup')"
      @update="fetch()"
    />
    <profile-modal
      id="addProfile"
      :class="$style.modal"
      :profiles="profiles"
      @close="closeModal('addProfile')"
      @update="getProfiles()"
    />
    <change-profile-modal
      id="changeProfile"
      :class="$style.modal"
      @cancel="cancelProfileChange()"
      @setProfile="setProfile()"
    />
    <drag-modal
      id="Drag"
      :class="$style.modal"
      :selected="draggedComputer"
      @close="closeModal('Drag')"
      @drag="drag()"
    />
    <settings-modal
      v-if="selectedComputer"
      id="settingsChanges"
      :class="$style.modal"
      :selected="compProfileName"
      @cancel="cancelSettingsChange()"
      @save="changeSettings()"
    />
    <save-settings-modal
      v-if="selectedComputer"
      id="saveWhenRedirect"
      :class="$style.modal"
      :selected="compProfileName"
      @save="saveChanges()"
      @dontSave="discardChanges()"
      @cancel="closeModal('saveWhenRedirect')"
    />
    <edit-profiles-modal
      id="editProfList"
      :profiles="profiles"
      :class="$style.modal"
      @close="closeModal('editProfList')"
      @update="updateProfiles()"
    />
  </article>
</template>

<script>
import Vue from 'vue';
import VueSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import Chip from '@/components/pages/computers/chip.vue';
import { getComputers } from '@/api/methods/computers';
import UserModal from '@/views/settings/agents/modals/addUserModal.vue';
import GroupModal from '@/views/settings/agents/modals/addGroupModal.vue';
import ProfileModal from '@/views/settings/agents/modals/addProfileModal.vue';
import DeleteUserModal from '@/views/settings/agents/modals/deleteModal.vue';
import DeleteGroupModal from '@/views/settings/agents/modals/deleteGroupModal.vue';
import DragModal from '@/views/settings/agents/modals/confirmDragModal.vue';
import ChangeProfileModal from '@/views/settings/agents/modals/changeProfileModal.vue';
import SettingsModal from '@/views/settings/agents/modals/settingsModal.vue';
import EditProfilesModal from '@/views/settings/agents/modals/editProfilesModal.vue';
import UserListModal from '@/views/settings/agents/modals/addUsersFromListModal.vue';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import SaveSettingsModal from '@/views/settings/agents/agent/settings/save-settings-modal.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import {
  setUserDep,
  setDepParent,
  renameUser,
  renameGroup,
  getProfiles,
  setProfileForPC,
  setProfileForDep,
  saveSettings,
  getMaxUsers,
  getMaxComputers,
} from '@/api/methods/settings';
import 'vue-select/src/scss/vue-select.scss';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    Chip,
    UserModal,
    GroupModal,
    dragTreeTable,
    DeleteUserModal,
    DeleteGroupModal,
    ProfileModal,
    VueSelect,
    UserListModal,
    DragModal,
    ChangeProfileModal,
    SettingsModal,
    EditProfilesModal,
    SaveSettingsModal,
    Tabset,
  },
  data: () => ({
    tabs: [
      {
        title: 'Общие настройки',
        active: true,
        link: 'agents-agent-settings',
        default: 'log',
      },
      {
        title: 'Настройки безопасности',
        active: false,
        link: 'agents-agent-security',
        default: 'apps',
      },
      {
        title: 'Оповещения',
        active: false,
        link: 'agents-agent-notifications',
        default: 'securityNotifications',
      },
      {
        title: 'Нестандартное поведение',
        active: false,
        link: 'agents-agent-irregular',
        default: 'irregular',
      },
    ],
    tabStyle: {
      fontSize: '14px',
    },
    treeData: {
      columns: [
        {
          type: 'selection',
          title: 'Название',
          field: 'title',
          width: 200,
          align: 'left',
          flex: 3,
        },
        {
          title: 'Имя компьютера',
          field: 'user',
          width: 200,
          align: 'left',
          flex: 2,
        },
        {
          type: 'action',
          title: 'Действия',
          width: 100,
          align: 'left',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
    profiles: [],
    tree: [],
    groups: [],
    computers: [],
    categories: [
      { name: 'Автостарт и размер логов', slug: 'log' },
      { name: 'Основные настройки сбора', slug: 'common' },
      { name: 'Скриншоты', slug: 'screenshots' },
      { name: 'Внешние накопители', slug: 'drives' },
      { name: 'Файлы и папки', slug: 'files' },
      { name: 'Теневое копирование', slug: 'shadowCopy' },
      { name: 'Internet логи', slug: 'internet' },
      { name: 'Учет рабочего времени', slug: 'worktime' },
      { name: 'Webcam/microphone', slug: 'webcam' },
    ],
    notificationCategories: [
      { name: 'Оповещения безопасности', slug: 'securityNotifications' },
      { name: 'Оповещения продуктивности', slug: 'productivityNotifications' },
    ],
    securityCategories: [
      { name: 'Приложения', slug: 'apps' },
      { name: 'Ссылки', slug: 'forbidden' },
      { name: 'Текст', slug: 'text' },
      { name: 'Файлы', slug: 'files' },
    ],
    addUserOptions: [
      { name: 'Добавить вручную', link: '#', id: 'modalUser' },
      { name: 'Добавить из списка', link: '#', id: 'modalUserList' },
    ],
    addGroupElementId: 'modalGroup',
    selectedItem: undefined,
    selectedProfile: undefined,
    list: undefined,
    draggedComputer: undefined,
    parent: undefined,
    maxUsers: 0,
    maxComputers: 0,
    targetComp: undefined,
    buildVersion: 0,
  }),
  computed: {
    compNumber() {
      return this.computers.filter((item) => item.userType === 0).length;
    },
    userNumber() {
      return this.computers.filter((item) => item.userType === 1).length;
    },
    compProfileName() {
      if (this.profiles.length > 0 && this.selectedComputer) {
        return this.profiles.find(
          (i) => i.id === this.selectedComputer.profileId,
        ).name;
      }
      return '';
    },
    smthChanged() {
      return this.$store.getters['settings/notEmpty'];
    },
    openedSettingsBlock() {
      if (this.$route.params.log) return this.$route.params.log;
      return false;
    },
    selectedComputer() {
      let activeItem;
      if (
        this.$route.params.computer
        && (this.computers.length > 0 || this.groups.length > 0)
      ) {
        const id = parseInt(this.$route.params.computer, 10);

        if (this.$route.params.type === 'computer') {
          activeItem = this.computers.find((i) => i.id === id);
          if (activeItem) activeItem.title = activeItem.nameUser;
        }
        if (this.$route.params.type === 'folder') {
          activeItem = this.groups.find((i) => i.id === id);
          if (activeItem) activeItem.title = activeItem.name;
        }

        if (activeItem) {
          return activeItem;
        }
      }

      return null;
    },
  },
  watch: {
    '$route.params': function () {
      this.getSelectedProfile();
      this.$store.commit('settings/setProfile', this.selectedProfile);
    },
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/refresh') {
          await this.refresh();
        }
      },
    });
  },
  async created() {
    this.buildVersion = this.$store.getters['user/buildVersion'];
    if (this.buildVersion !== 0) {
      this.categories = [
        { name: 'Автостарт и размер логов', slug: 'log' },
        { name: 'Основные настройки сбора', slug: 'common' },
        { name: 'Скриншоты', slug: 'screenshots' },
        { name: 'Учет рабочего времени', slug: 'worktime' },
      ];
    }
    await this.refresh();
    await this.getMaxUsersNumber();
    const currentTab = this.tabs.find((tab) => tab.link === this.$route.name);
    this.tabs.forEach((item) => {
      item.active = false;
    });
    if (currentTab) currentTab.active = true;
  },

  methods: {
    openNewTab(index) {
      if (this.tabs[index].default) {
        this.$router.push({
          name: this.tabs[index].link,
          params: { log: this.tabs[index].default },
        });
      } else {
        this.$router.push({
          name: this.tabs[index].link,
        });
      }

      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
    redirect(model) {
      if (this.smthChanged) {
        this.targetComp = model;
        this.openModal('saveWhenRedirect');
      } else {
        this.$router.push({
          name: 'agents-agent',
          params: { type: model.type, computer: model.id },
        });
      }
    },
    async getMaxUsersNumber() {
      this.maxUsers = await getMaxUsers();
      this.maxComputers = await getMaxComputers();
    },
    async updateProfiles() {
      this.closeModal('editProfList');
      this.refresh();
    },
    async setProfile() {
      this.closeModal('changeProfile');
      if (this.smthChanged) this.openModal('settingsChanges');
      else await this.applyProfileSettings();
    },
    cancelProfileChange() {
      this.closeModal('changeProfile');
      if (this.selectedComputer) this.selectedProfile = this.selectedComputer.profileId;
    },
    cancelChanges() {
      this.$store.commit('settings/clearChangedSettings');
      this.$store.dispatch('settings/cancel');
    },
    async cancelSettingsChange() {
      this.closeModal('settingsChanges');
      this.$store.commit('settings/clearChangedSettings');
      await this.applyProfileSettings();
    },
    async changeSettings() {
      this.closeModal('settingsChanges');
      await this.saveAllSettings(this.selectedComputer.profileId);
      await this.applyProfileSettings();
    },
    async saveChanges() {
      this.closeModal('saveWhenRedirect');
      await this.saveAllSettings(this.selectedComputer.profileId);
      this.$router.push({
        name: 'agents-agent',
        params: { type: this.targetComp.type, computer: this.targetComp.id },
      });
      this.targetComp = null;
    },
    discardChanges() {
      this.closeModal('saveWhenRedirect');
      this.$store.commit('settings/clearChangedSettings');
      this.$router.push({
        name: 'agents-agent',
        params: { type: this.targetComp.type, computer: this.targetComp.id },
      });
      this.targetComp = null;
    },
    async saveAllSettings(profile) {
      if (this.smthChanged) {
        const acsel = this.$store.getters['settings/acsel'];
        let continueSave = true;
        if (acsel.checked.length > 0) {
          const domain = acsel.checked.find((a) => a.stype === 10);
          if (domain && domain.sval.trim() === '') {
            Vue.$toast.open({
              message:
                'Пожалуйста, укажите домен почты, об отправке на которую не делать уведомлений',
              type: 'error',
            });
            continueSave = false;
          }
        }
        if (continueSave) {
          const settings = {};
          settings.idle = this.$store.getters['settings/idleSettings'];
          settings.log = this.$store.getters['settings/logSettings'];
          settings.common = this.$store.getters['settings/commonSettings'];
          settings.screenshots = this.$store.getters['settings/screenshotSettings'];
          settings.shadowCopy = this.$store.getters['settings/shadowSettings'];
          settings.files = this.$store.getters['settings/fileSettings'];
          settings.index = this.$store.getters['settings/indexSettings'];
          settings.drives = this.$store.getters['settings/driveSettings'];
          settings.internet = this.$store.getters['settings/inetSettings'];
          settings.webcam = this.$store.getters['settings/webcamSettings'];
          settings.notifications = this.$store.getters['settings/notificationSettings'];
          const profileName = this.profiles.find((i) => i.id === profile).name;
          await saveSettings(
            this.$store.getters['settings/security'],
            this.$store.getters['settings/acsel'],
            settings,
            profile,
            profileName,
            this.$store.getters['settings/prodProfile'],
            this.$store.getters['settings/wtProfile'],
          );

          this.$store.commit('settings/clearChangedSettings');
        }
      }
    },
    async refresh() {
      await this.fetch();
      await this.getProfiles();
      this.getSelectedProfile();
      this.$store.commit('settings/setProfile', this.selectedProfile);
      this.$store.dispatch('settings/paramChanged');
    },
    getSelectedProfile() {
      if (
        this.$route.params.computer
        && this.profiles
        && this.selectedComputer
      ) {
        this.selectedProfile = this.profiles.find(
          (i) => i.id === this.selectedComputer.profileId,
        ).id;
      }
    },
    async applyProfileSettings() {
      if (this.selectedProfile) {
        if (this.$route.params.type === 'computer') {
          await setProfileForPC(
            this.selectedProfile,
            this.$route.params.computer,
          );
        } else {
          await setProfileForDep(
            this.selectedProfile,
            this.$route.params.computer,
          );
        }
        await this.fetch();
        this.$store.dispatch('settings/paramChanged');
        this.getSelectedProfile();
        this.$store.commit('settings/setProfile', this.selectedProfile);
      }
    },
    allowDrag(row, row2) {
      if (row2.type === 'computer') return false;
      return true;
    },
    async drag() {
      if (this.draggedComputer.type === 'computer') await setUserDep(this.draggedComputer.id, this.parent);
      else await setDepParent(this.draggedComputer.id, this.parent);
      this.treeData.lists = this.list;

      await this.fetch();
      this.getSelectedProfile();

      this.closeModal('Drag');
    },
    onTreeDataChange(list, computer, group, target) {
      if (target !== 'top') {
        this.list = list;
        this.draggedComputer = computer;
        let parent = group.id;
        if (target === 'bottom') parent = group.parentId;
        this.parent = parent;
        this.openModal('Drag');
      }
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    openDeleteModal(model) {
      this.selectedItem = model.id;
      if (model.type === 'computer') {
        document.getElementById('deleteUser').style.display = 'flex';
      } else document.getElementById('deleteGroup').style.display = 'flex';
    },
    changeName(model) {
      if (model.type === 'computer') renameUser(model.id, model.title);
      else renameGroup(model.id, model.title);
    },
    setNodeAttributes(node) {
      node.ident = node.id + node.name;
      if (node.type === 'folder') {
        node.opened = true;
        node.open = true;
        node.title = node.name;
        node.isContainChildren = true;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        node.opened = false;
        node.dropDisabled = true;
        node.title = node.nameUser;
        node.user = node.name;
      }
    },
    async getProfiles() {
      this.profiles = await getProfiles();
    },
    async fetch() {
      this.computers = [];
      this.groups = [];
      return getComputers().then(({ tree, computers, groups }) => {
        tree.forEach((item) => this.setNodeAttributes(item));
        this.tree = tree;
        this.treeData.lists = tree;
        groups.forEach((group) => {
          if (group.id) this.groups.push(group);
        });
        computers.forEach((comp) => {
          if (comp.id) this.computers.push(comp);
        });
      });
    },
  },
};
</script>

<style lang="scss" module>
.grid {
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  display: flex;
  background: white;
  height: 100%;

  overflow: auto;
  display: flex;
}

.gridItem {
  flex: 0 0 55%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridItemLeft {
  flex: 0 0 45%;
}

.settings {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  overflow-x: hidden;
  min-height: 200px;
}

.gridItemComputers {
  display: flex;
}

.gridItemComputersTable {
  flex: 1 1 100%;
  height: 100%;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid $borderColor;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridItemComputersTableContentFooter {
  border-top: 1px solid $borderColor;
  position: sticky;
  bottom: 0;
}

.gridItemComputersSidebar {
  flex-shrink: 0;
}

.computerHeader {
  padding: 20px 20px 10px 20px;
  border-top: 1px solid $borderColor;
  border-bottom-width: 0;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: 10px; // 20px total
}

.addUserButton {
  border: 1px solid white;
  background: #2985bf;
  cursor: pointer;
  height: 30px;
  color: white;
  width: 100%;
}

.addProfileButton {
  border: 0px;
  background: white;
  cursor: pointer;
  height: 30px;
  color: #2985bf;
  width: 100%;
  font-size: 20px;
  font-weight: 700;

  &:hover {
    background: #2985bf;
    color: white;
    border: 1px solid white;
  }
}

.buttonsContainer {
  display: inline-flex;
  width: 100%;
}

.buttonContainer {
  width: 50%;
}

.dropdownСontent {
  display: none;
  position: absolute;
  background-color: white;
  //width: 50%;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  //bottom: 30px;
}

.dropdownСontent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownСontent a:hover {
  background-color: #eee;
}

.dropdown:hover .dropdownСontent {
  display: block;
}

.modal {
  display: none;
  z-index: 3;
}

.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;

    &:hover {
      background-color: #d0cfcf;
    }
  }

  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.profileSelect {
  width: 25%;
  margin-left: 5px;
}

.selectContainer {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  align-items: center;
}

.select {
  z-index: 0;
}

.button {
  margin: 5px;
  height: 30px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.buttonCancel {
  background: $branchColor;
  border: $branchColor;
}

.profileLabel {
  margin-right: 5px;
  font-size: 14px;
}

.vueSelect {
  width: 45%;
  margin-right: 5px;
}

.deleteIcon {
  cursor: pointer;
  margin: 5px;
}

.buttonSave {
  height: 36px;
  margin: 10px 10px 10px 0px;
  width: 160px;
  background: $branchColor;
  border: $branchColor;
  cursor: auto;
}

.buttonActive {
  background: #2985bf;
  border: #2985bf;
  cursor: pointer;
}

.buttonRedActive {
  background: $red;
  border: $red;
  cursor: pointer;
}

.buttonDelete {
  height: 30px;
  margin: 5px;
  width: 140px;
}

.warning {
  font-size: 13px;
  color: $red;
  padding: 5px 0px;
}

.buttonEdit {
  height: 36px;
  min-width: 158px;
}

.compNumber {
  position: sticky;
  height: 30px;
  background: white;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.chipTitle {
  display: flex;
  align-items: center;
}

.tabs {
  margin: 15px 0px;
}

.compNumberText {
  margin-right: 30px;
}
</style>

<style>
.drag-tree-table {
  margin: 0;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.drag-tree-table-header {
  background: #f5f5f5;
  line-height: 20px;
}

.tree-row {
  line-height: 20px;
}

.tree-column {
  text-align: left;
}

.vs__dropdown-menu {
  z-index: 1;
}

.vs__dropdown-option--highlight {
  background: #f5f5f5;
  color: black;
}

.agentsSelect .v-select .vs__dropdown-toggle {
  height: 36px;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
}

.agentsSelect .v-select .vs__selected-options {
  padding: 4px 0 0 9px;
}

.agentsSelect .v-select .vs__selected-options .vs__selected {
  line-height: 20px;
}

.agentsSelect .v-select ul {
  width: -webkit-fill-available;
}

.agentsSelect .v-select ul>li {
  width: auto;
}

.agentsSelect .vs__selected {
  margin: 4px 2px 3px 2px;
}
</style>

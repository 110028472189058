<template>
  <modal
    header-text="Добавление нового пользователя"
    button-text="Добавить"
    :class="$style.modal"
    @action="addUser()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label :class="$style.label"> Имя пользователя</label>
      <input
        v-model="name.compName"
        type="text"
        :class="$style.input"
      >
    </div>

    <div :class="$style.inputContainer">
      <label :class="$style.label"> Отображаемое имя пользователя</label>
      <input
        v-model="name.displayName"
        type="text"
        :class="$style.input"
      >
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import { addNewUser, getMaxComputers } from '@/api/methods/settings';
import Modal from '@/components/common/modal/settings-modal.vue';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: { Modal },
  props: ['users'],
  data: () => ({
    name: { compName: '', displayName: '' },
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    async addUser() {
      const maxUsers = await getMaxComputers();

      if (this.users.length < maxUsers) {
        const result = await addNewUser(
          this.name.displayName,
          this.name.compName,
        );
        if (result === 'success') {
          this.name.compName = '';
          this.name.displayName = '';
        }

        this.$emit('update');
      } else {
        Vue.$toast.open({
          message:
            'Достигнуто максимальное число компьютеров, предусмотренное Вашей лицензией',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}

.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.label {
  width: 40%;
}</style>

<template>
  <modal
    header-text=""
    button-text="Да"
    @action="confirmDrag()"
    @close="$emit('close')"
  >
    <div v-if="selected" :class="$style.inputContainer">
      Вы действительно хотите переместить {{ selected.title }} ?
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';

export default {
  components: { Modal },
  props: ['selected'],
  methods: {
    close() {
      this.$emit('close');
    },
    async confirmDrag() {
      this.$emit('drag');
    },
  },
};
</script>

<style lang="scss" module>
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>

<template>
  <div
    :class="$style.btnRefresh"
    aria-label="Обновить"
    tabindex="0"
    type="role"
    @click="clickHandler"
  >
    <div>
      <img
        :class="$style.img"
        src="@/assets/images/icons/navigation/refresh.svg"
        alt=""
      >
    </div>
    <p :class="$style.text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['text'],
  methods: {
    clickHandler() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" module>
.btnRefresh {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.text {
  color: $black;
  font-size: 15px;
  line-height: 18px;
  margin-left: 10px;
}
.img {
  min-width: 15px;
}
</style>

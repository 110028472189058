<template>
  <modal
    header-text="Профили настроек"
    button-text="Сохранить"
    :hide-footer="add"
    :hide-cross="add"
    @action="save()"
    @close="cancel()"
  >
    <template v-if="add" v-slot:header-left>
      <div :class="$style.arrow" title="Назад" @click="add = false">
        <img
          src="@/assets/images/icons/navigation/arrow-left.svg"
          :class="$style.svg"
        >
      </div>
    </template>
    <div v-if="add" :class="$style.addition">
      <div :class="$style.title">
        Добавление нового профиля
      </div>
      <div :class="$style.inputContainer">
        <label :class="$style.label"> Название профиля </label>
        <input v-model="profileName" type="text" :class="$style.input">
      </div>

      <div :class="$style.inputContainer">
        <label :class="$style.label"> Создать на основе профиля</label>
        <select v-model="selectedProfile" :class="$style.input">
          <option
            v-for="option in profiles"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <div :class="$style.buttonContainer">
        <button :class="$style.button" @click="addProfile()">
          Добавить
        </button>
      </div>
    </div>
    <div v-if="!add">
      <div :class="$style.buttonOpenContainer">
        <button :class="[$style.button, $style.buttonOpen]" @click="add = true">
          <div :class="$style.plus">
            +
          </div>
          Добавить новый профиль
        </button>
      </div>
      <scrollable-container :class="$style.scrollableContainer">
        <table>
          <tbody>
            <tr
              v-for="(profile, index) in shownProfiles"
              :key="index"
              :class="$style.row"
            >
              <td :class="$style.profile">
                {{ profile.name }}
              </td>
              <td>
                <img
                  v-if="profile.id !== 1"
                  title="Удалить"
                  src="@/assets/images/icons/navigation/trash.svg"
                  :class="$style.deleteIcon"
                  @click="remove(profile)"
                >
              </td>
            </tr>
            <tr
              v-for="(prof, ind) in newProfiles"
              :key="ind"
              :class="$style.row"
            >
              <td>
                {{ prof.name }}
              </td>
              <td>
                <button>Удалить</button>
              </td>
            </tr>
          </tbody>
        </table>
      </scrollable-container>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import { saveProfiles } from '@/api/methods/settings';

export default {
  components: { Modal, ScrollableContainer },
  props: ['profiles'],
  data: () => ({
    newProfiles: [],
    profileName: '',
    selectedProfile: 1,
    add: false,
    addedProfiles: [],
    removedProfiles: [],
  }),
  computed: {
    shownProfiles() {
      let shown = this.profiles;
      if (this.removedProfiles.length > 0) {
        shown = this.diff(this.removedProfiles, this.profiles);
      }
      shown = shown.concat(this.addedProfiles);
      return shown;
    },
  },
  methods: {
    async save() {
      if (this.addedProfiles.length > 0 || this.removedProfiles.length > 0) {
        await saveProfiles(this.addedProfiles, this.removedProfiles);
      }
      this.addedProfiles = [];
      this.removedProfiles = [];
      this.$emit('update');
    },
    cancel() {
      this.addedProfiles = [];
      this.removedProfiles = [];
      this.$emit('close');
    },
    diff(first, second) {
      const a = new Set(first);
      return [...second.filter((x) => !a.has(x))];
    },
    addProfile() {
      this.addedProfiles.push({
        name: this.profileName,
        base: this.selectedProfile,
      });
      this.profileName = '';
      this.selectedProfile = 1;
      this.add = false;
    },
    remove(profile) {
      const index = this.addedProfiles.indexOf(profile);
      if (index > -1) this.addedProfiles.splice(index, 1);
      else this.removedProfiles.push(profile);
    },
  },
};
</script>

<style lang="scss" module>
.scrollableContainer {
  height: 200px;
  margin: 30px;
}
.table {
  width: 100%;
}
.row {
  cursor: pointer;
  height: 30px;
  border-bottom: 1px solid #cdcdcd;

  &:hover {
    background: $light-gray;
  }
}
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.label {
  width: 40%;
}
.button {
  margin: 5px;
  height: 30px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.buttonOpen {
  width: 220px;
  margin-left: 20px;
  display: flex;
  align-items: baseline;
}
.buttonOpenContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.profile {
  width: 90%;
  padding: 10px;
}
.addition {
  margin: 30px;
  height: 307px;
}
.deleteIcon {
  margin-top: 12px;
}
.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.arrow {
  display: flex;
  cursor: pointer;
}
.svg {
  width: 30px;
}
.plus {
  font-weight: 700;
  font-size: 20px;
  padding: 0px 5px;
}
.title {
  display: flex;
  justify-content: center;
  margin: 30px;
  font-size: 14px;
  font-weight: 500;
}
</style>

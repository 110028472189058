<template>
  <router-link
    :to="to"
    :class="classList"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: ['slug', 'route'],
  computed: {
    active() {
      // TODO: Рефакторинг на router-link-active-class
      return this.$route.params.log === this.slug;
    },
    to() {
      const { query } = this.$route;
      return this.$route.params.computer
        ? { name: this.route.active, params: { log: this.slug }, query }
        : { name: this.route.inactive, query };
    },
    classList() {
      return [
        this.$style.chip,
        {
          [this.$style.active]: this.active,
          [this.$style.disabled]: !this.$route.params.computer,
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.chip {
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 6px 10px;
  color: $light-black;
  background: $light-gray;
  transition: all 0.1s ease-out;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  gap: 3px;
}

.active {
  color: $blue;
  background: $light-blue;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>

<template>
  <modal
    header-text="Добавление новой группы"
    button-text="Добавить"
    @action="addGroup()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label> Название группы</label>
      <input v-model="groupName" type="text" :class="$style.input">
    </div>

    <div :class="$style.inputContainer">
      <label> Профиль настроек</label>

      <select v-model="selectedProfile" :class="$style.input">
        <option v-for="option in profiles" :key="option.id" :value="option.id">
          {{ option.name }}
        </option>
      </select>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { getProfiles, addNewGroup } from '@/api/methods/settings';

export default {
  components: { Modal },
  data: () => ({
    groupName: '',
    selectedProfile: '',
    profiles: [],
  }),
  created() {
    this.getGroupProfiles();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async getGroupProfiles() {
      this.profiles = await getProfiles();
      this.selectedProfile = this.profiles[0].id;
    },
    async addGroup() {
      const result = await addNewGroup(this.groupName, this.selectedProfile);
      if (result === 'success') this.groupName = '';
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 55%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>

<template>
  <modal
    header-text="Список компьютеров для добавления"
    button-text="Добавить"
    :hide="hide"
    :not-active="checkedComputers.length === 0"
    class="modalAddList"
    @action="addUsers()"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <refresh
        :class="$style.refr"
        text="Обновить"
        @click="getRemoteComputers()"
      />
    </template>

    <div
      v-if="computers.length === 0"
      :class="$style.placeHolder"
    >
      Список пуст
    </div>
    <div v-else>
      <div :class="$style.compNumber">
        Итоговое количество пользователей:
        {{ totalComputers }} /
        {{ maxUsers }}
      </div>
      <scrollable-container :class="$style.scrollableContainer">
        <table>
          <thead>
            <tr :class="$style.columns">
              <th :style="{ width: '10%' }" />
              <th :class="$style.textHeader">
                Имя компьютера
              </th>
              <th :class="$style.textHeader">
                Отображаемое имя
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="computer in computers"
              :key="computer.id + computer.name"
              :class="[$style.row, $style.customCheckBoxContainer]"
              @click="check(computer)"
            >
              <td :class="$style.checkboxCont">
                <input
                  :id="computer.id"
                  v-model="checkedComputers"
                  type="checkbox"
                  :value="computer"
                  :class="$style.customCheckbox"
                >
                <label><check-mark /></label>
              </td>
              <td :style="{ width: '45%' }">
                {{ computer.name }}
              </td>
              <td
                :class="$style.inputContainer"
                :style="{ width: '45%' }"
              >
                <input
                  v-model="computer.title"
                  type="text"
                  :class="$style.input"
                  @click.stop=""
                >
              </td>
            </tr>
          </tbody>
        </table>
      </scrollable-container>
    </div>
  </modal>
</template>

<script>
/* eslint-disable */
import Modal from '@/components/common/modal/settings-modal.vue';
import { getCompRemote, addRemote, getMaxUsers } from '@/api/methods/settings';
import CheckMark from '@/components/common/filter/check-mark.vue';
import Refresh from '@/components/common/filter/refresh.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, CheckMark, Refresh, ScrollableContainer },
  props: ['users'],
  data: () => ({
    groupName: '',
    selectedProfile: '',
    computers: [],
    checkedComputers: [],
    hide: false,
    maxUsers: null,
  }),
  async created() {
    await this.getRemoteComputers();
    this.maxUsers = await getMaxUsers();
  },
  computed: {
    isAbleToCheckComputer() {
      return this.maxUsers > this.totalComputers;
    },
    totalComputers() {
      return (this.checkedComputers?.length ?? 0) + (this.users?.filter(item => item.userType === 1).length ?? 0);
    },
  },
  methods: {
    check(computer) {
      const index = this.checkedComputers.indexOf(computer);
      if (index === -1) {
        if (this.isAbleToCheckComputer) this.checkedComputers.push(computer);
      } else this.checkedComputers.splice(index, 1);
    },
    close() {
      this.$emit('close');
    },
    async getRemoteComputers() {
      this.computers = await getCompRemote();
      if (this.computers.length === 0) this.hide = true;
    },
    async addUsers() {
      if (this.checkedComputers.length > 0) {
        const result = await addRemote(this.checkedComputers);
        if (result === 'success') {
          await this.getRemoteComputers();
          this.close();
          this.checkedComputers = [];
        }
        this.$emit('update');
      }
    },
  },
};
</script>

<style lang="scss" module>
.scrollableContainer {
  height: 200px;
  margin: 20px;
  padding: 0px 5px;
}

.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.placeHolder {
  font-size: 14px;
  cursor: default;
}

.input {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}

.columns {
  position: sticky;
  z-index: 5;
  top: 0;
  background: white;
  height: 20px;
  font-weight: 500;
  cursor: pointer;
}

.row {
  cursor: pointer;
  height: 22px;

  &:hover {
    background: $light-gray;
  }
}

.customCheckBoxContainer {
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input:checked+label>svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }

  label:active::after {
    background-color: #fbfbfb;
  }

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }

  input:checked+label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }

  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

.compNumber {
  position: sticky;
  height: 30px;
  background: white;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.compNumberError {
  color: red;
}

.refr {
  margin-right: 15px;
}

.textHeader {
  width: 45%;
  text-align: left;
  padding: 0px 10px;
}

.checkboxCont {
  width: 10%;
  vertical-align: middle;
  padding-left: 5px;
}
</style>

<style >
.modalAddList .settings-modal_modal_3LaCC {
  min-width: 444px !important;
}
</style>

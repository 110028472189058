<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="delGroup(1)"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      Удалить группу и все её содержимое?
    </div>

    <template v-slot:footer>
      <button :class="$style.button" @click="delGroup(0)">
        Удалить только группу
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { deleteGroup } from '@/api/methods/settings';

export default {
  components: { Modal },
  props: ['selected'],
  methods: {
    close() {
      this.$emit('close');
    },
    async delGroup(groupOnly) {
      await deleteGroup(this.selected, groupOnly);
      this.close();
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}

.button {
  height: 30px;
  cursor: pointer;
  margin: 0px 5px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
</style>
